@import "../../variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.homepage-hero-banner-container {
    max-height: 700px;

    .homepage-hero-banner-image {
        object-fit: cover;
        width: 100%;
        height: 270px;

        @include media-breakpoint-up(md) {
            width: 50%;
            height: 700px;
        }
    }

    .homepage-hero-banner-heading-wrapper {
        background-color: $white;
        width: 100%;
        height: 420px;
        padding: 32px 16px;
        gap: 24px;

        @include media-breakpoint-up(md) {
            width: 50%;
            height: 700px;
            gap: 40px
        }
    }

    .homepage-hero-banner-heading-svg {
        max-width: 480px;
        width: 70vw;
    }

    .homepage-hero-banner-heading-title {
        font-family: $font-qualion;
        color: $dark-green;
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
        max-width: 100%;

        @include media-breakpoint-up(md) {
            font-size: 40px;
            line-height: 48px;
            max-width: 544px;
        }
    }

    .homepage-hero-banner-heading-subtitle {
        font-family: $font-qualion;
        color: $dark-green;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        max-width: 100%;

        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 36px;
            max-width: 544px;
        }
    }

    .homepage-hero-banner-button-primary {
        padding: 10px 16px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-transform: none;
        height: 44px;

        @include media-breakpoint-up(md) {
            padding: 16px 24px;
            height: 56px;
        }
    }

    .homepage-hero-banner-button-secondary {
        font-family: $font-qualion;
        color: $dark-green;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        border-bottom: 1px solid $dark-green;
        text-transform: uppercase;

        &:hover {
            text-decoration: none;
            color: $light-green-tertiary;
        }
    }

    .homepage-feefo-product-reviews-summary-rating {
        gap: 16px;
    }

    .homepage-feefo-product-reviews-summary-rating-text {
        color: $dark-green;
        font-family: $font-qualion;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
    }

    .homepage-feefo-product-reviews-summary-rating-border {
        border-right: 1px solid $dark-green;
    }

    .homepage-feefo-stars {
        gap: 16px;

        .homepage-feefo-star-rating {
            font-size: 16px;
        }

        .homepage-feefo-back-stars {
            gap: 4px;
            color: $neutral-20;
        }

        .homepage-feefo-front-stars {
            gap: 4px;
            color: $dark-green;
            top: 0;
        }

        .homepage-feefo-stars-link {
            border-radius: 0;
            color: $dark-green;
            font-family: $font-qualion;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            border-bottom: 1px solid $dark-green;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &.full {
        .homepage-hero-banner-image {
            width: 100%;
            height: 375px;
    
            @include media-breakpoint-up(md) {
                width: 100%;
                height: 100%;
                max-height: 700px;
            }
        }

        .homepage-hero-banner-heading-wrapper {
            background-color: transparent;
            position: absolute;
            height: auto;
            width: auto;
            gap: 0;
            top: 0;

            @include media-breakpoint-up(md) {
                gap: 40px;
                top: auto;
            }
        }

        .homepage-hero-banner-heading-title,
        .homepage-hero-banner-heading-subtitle {
            color: $white;
    
            @include media-breakpoint-up(md) {
                max-width: 720px;
            }
        }

        .homepage-hero-banner-heading-subtitle {
            @include media-breakpoint-up(md) {
                font-size: 24px;
            }
        }

        .homepage-hero-banner-button-primary {
            color: $dark-green;
            background-color: $white;

            &:hover {
                background-color: $neutral-1;
            }
        }
    
        .homepage-hero-banner-button-secondary {
            color: $white;
            border-color: $white;

            &:hover {
                color: $neutral-1;
                border-color: $neutral-1;
                opacity: 0.5;
            }
        }

        .background-tint {
            top: 0;
            background: rgba(0, 0, 0, 0.25);
            height: 375px;

            @include media-breakpoint-up(md) {
                height: 100%;
            }
        }

        .homepage-hero-banner-full-rating {
            background-color: $white;
            width: 100%;

            @include media-breakpoint-up(md) {
                position: absolute;
                bottom: 0;
                width: auto;
                border-radius: 26px 26px 0px 0px;
            }
        }
    }
}